<template>
  <div class="page">
    <Navbar isBack="true" title="签到积分" />
    <div class="page_box">
      <div class="vip_level_nav">签到积分</div>
      <div class="vip_level_dec">
        玩家在平台签到后，可以获取相应的积分奖励，不同的VIP等级可以获得不同的积分奖励，通过积分可以兑换相对应的礼品，奖励积分如下：
      </div>
      <div class="vip_level_table">
        <div class="vip_level_tr">
          <div class="vip_level_tb">VIP等级</div>
          <div class="vip_level_tb">签到奖励积分</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip 0~3</div>
          <div class="vip_level_tb">3</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip 4~5</div>
          <div class="vip_level_tb">5</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip 6~7</div>
          <div class="vip_level_tb">7</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip 8~9</div>
          <div class="vip_level_tb">9</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip 10</div>
          <div class="vip_level_tb">12</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
$vip-color: #c37c00;
.page_box {
  padding: 0 0.22rem 1rem 0.22rem;
}
.vip_level_nav {
  margin: 0.4rem 0 0.1rem 0;
  padding: 0 0.3rem;
  line-height: 0.4rem;
  border: 1px solid $vip-color;
  color: $vip-color;
  border-radius: 1rem;
}
.vip_level_img {
  margin: 0.3rem 0;
}
.vip_level_dec {
  line-height: 0.4rem;
  font-size: 0.23rem;
}
.vip_level_table {
  margin-top: 0.3rem;
  width: 100%;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.vip_level_tr {
  display: flex;
}
.vip_level_tr:nth-child(1) {
  background-color: #fde2b0;
}
.vip_level_tr:nth-child(1) .vip_level_tb {
  height: 0.9rem;
  color: $vip-color;
}
.vip_level_tb {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 0.6rem;
  font-size: 0.23rem;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>